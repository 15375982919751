/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
};

//Home Page
const greeting = {
  title: "Hi",
  title2: " I'm Chetan Chitriv",
  logo_name: "Chetan Chitriv",
  // nickname: "harry / picleric",
  full_name: "Chetan Chitriv",
  subTitle1: "Full Stack Developement.",
  subTitle2: "Wordpress Development.",
  subTitle3: "Mobile App Development.",
  subTitle4: "Life Long Learner.",
  // subTitle5: " App Developer.",
  subTitle: "Full Stack Developer, Life Long Learner.",
  resumeLink:
    "",
  mail: "chetanchitriv1@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/chetanchitriv",
  linkedin: "https://www.linkedin.com/in/chetan-c-267239285/",
  gmail: "chetanchitriv1@gmail.com",
  twitter: "",
};

const skills = {
  data: [
    {
      _id: "354c54b7-ffd8-40f1-972f-1d006733df25",
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive user interfaces and backend for web applications",
        "⚡ Building responsive website front end using React and Angular",
        "⚡ Creating application backend in Node, Express, MongoDB and Rest API",
        "⚡ Integration of third party services such as Firebase, Vercel etc.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0769ad",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#593D88",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#dd0031",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Express",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "gray",
          },
        },
        {
          skillName: "Material UI",
          fontAwesomeClassname: "simple-icons:uikit",
          style: {
            color: "#007FFF",
          },
        },
        {
          skillName: "Tailwind CSS",
          fontAwesomeClassname: "logos:tailwindcss-icon",
          style: {
            color: "#635BFF",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Framer Motion",
          fontAwesomeClassname: "logos:framer",
          style: {
            color: "#635BFF",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Styled Components",
          fontAwesomeClassname: "vscode-icons:file-type-styled",
          style: {
            color: "#635BFF",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#563d7c",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#FF6C37",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Cloudinary",
          fontAwesomeClassname: "logos:cloudinary-icon",
          style: {
            color: "#635BFF",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Stripe",
          fontAwesomeClassname: "logos:stripe",
          style: {
            color: "#635BFF",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      _id: "c026fe64-1542-42b0-84ab-9311840c169f",
      title: "Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "Google Cloud Platform",
          fontAwesomeClassname: "skill-icons:gcp-dark",
          style: {
            backgroundColor: "transparent",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Vercel",
          fontAwesomeClassname: "skill-icons:vercel-light",
          style: {
            color: "gray",
            fill: "gray",
            // backgroundColor: "white",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Amazon AWS ",
          fontAwesomeClassname: "logos:aws",
          style: {
            color: "#5b77ef",
            width: "1em",
            height: "1em",
          },
        },
        {
          skillName: "Microsoft Azure ",
          fontAwesomeClassname: "logos:azure",
          style: {
            color: "#5b77ef",
            width: "1em",
            height: "1em",
          },
        },
        // {
        //   skillName: "Microsoft Azure ",
        //   fontAwesomeClassname: "logos:",
        //   style: {
        //     color: "#5b77ef",
        //     width: "1em",
        //     height: "1em",
        //   },
        // },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Manoharbhai Patel Institute of Engineering and Technology",
      subtitle: "Bachelors in Electronic And Telecommunication (B.tech)",
      logo_path: "amrita_logo.png",
      alt_name: "RTMNU",
      duration: "2012 - 2016",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence, Machine Learning, Computer Vision etc.",
        "⚡ Apart from this, I have done courses on Cloud Computing and Full Stack Development.",
      ],
      website_link: "http://mietbhandara.ac.in/",
    },
    {
      title: "Shri Laxmanrao Mankar College of Polytechnic",
      subtitle: "Diploma In Electronic And Telecommunication",
      logo_path: "ggsipu_logo.png",
      alt_name: "GGSIPU",
      duration: "2009 - 2012",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development."
      ],
      // website_link: "http://www.ipu.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Frontend Developer Capstone",
      subtitle: "",
      logo_path: "meta_logo.png",
      certificate_link:
        "https://coursera.org/share/f80912b58e3afcfa49e6116210a2c9da",
      alt_name: "Meta",
      color_code: "#096ce1",
    },
    {
      title: "Advanced React",
      subtitle: "",
      logo_path: "meta_logo.png",
      certificate_link:
        "https://coursera.org/share/877acd7eaf9dd532a2cb5b103576967e",
      alt_name: "Meta",
      color_code: "#096ce1",
    },
    {
      title: "Java Programming: Build a Recommendation System",
      subtitle: "",
      logo_path: "duke-logo.jpg",
      certificate_link:
        "https://coursera.org/share/6af001cafc4b683bb38abdb9e81d0d79",
      alt_name: "Duke University",
      color_code: "#001a57",
    },
    {
      title: "React Basics",
      subtitle: "",
      logo_path: "meta_logo.png",
      certificate_link:
        "https://coursera.org/share/29c75a387fcaf2f1e2403f73473092b2",
      alt_name: "Meta",
      color_code: "#096ce1",
    },
    {
      title: "Programming with JavaScript",
      subtitle: "",
      logo_path: "meta_logo.png",
      certificate_link:
        "https://coursera.org/share/cb44f1ee2db3f50b83a56cbae15fcc74",
      alt_name: "Meta",
      color_code: "#096ce1",
    },
    {
      title: "HTML and CSS in depth",
      subtitle: "",
      logo_path: "meta_logo.png",
      certificate_link:
        "https://coursera.org/share/667de02731a480b76b5300c6612d174d",
      alt_name: "Meta",
      color_code: "#096ce1",
    },
    {
      title: "Java Programming: Principles of Software Design",
      subtitle: "",
      logo_path: "duke-logo.jpg",
      certificate_link:
        "https://coursera.org/share/f4e9af47fbb24381e04031f3cc8cfd9f",
      alt_name: "Duke University",
      color_code: "#001a57",
    },
    {
      title: "Java Programming: Solving Problems with Software",
      subtitle: "",
      logo_path: "duke-logo.jpg",
      certificate_link:
        "https://coursera.org/share/36411fcea7ea4fbb5c613ef4911f4668",
      alt_name: "Duke University",
      color_code: "#001a57",
    },
    {
      title: "Programming Foundations with JavaScript, HTML and CSS",
      subtitle: "",
      logo_path: "duke-logo.jpg",
      certificate_link:
        "https://coursera.org/share/0269c76c33997304d1842d4eef16815b",
      alt_name: "Duke University",
      color_code: "#001a57",
    },
    {
      title: "Android Application Development - II",
      subtitle: "- Dr. Adam Porter",
      logo_path: "maryland_logo.svg",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/GN48B7W9TU59",
      alt_name: "Stanford University",
      color_code: "#e11a2b",
    },
    {
      title: "Android Application Development - I",
      subtitle: "- Dr. Adam Porter",
      logo_path: "maryland_logo.svg",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/2H59Z22DDSU8",
      alt_name: "Stanford University",
      color_code: "#e11a2b",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Christian Hur",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/97WF8EZ4Y2YD",
      alt_name: "Coursera",
      color_code: "#0056d2",
    },
    {
      title: "ML and AI in GCP",
      subtitle: "- GCP Training",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/345a17d9-689f-4154-a3d3-c0396c98b3bc/badges/2862832",
      alt_name: "GCP",
      color_code: "#2fa9508a",
    },
    {
      title: "Foundational Infrastructure in GCP",
      subtitle: "- GCP Training",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/345a17d9-689f-4154-a3d3-c0396c98b3bc/badges/2653409",
      alt_name: "GCP",
      color_code: "#4087f08a",
    },
    {
      title: "Networking & Security in GCP",
      subtitle: "- GCP Training",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/345a17d9-689f-4154-a3d3-c0396c98b3bc/badges/2510430",
      alt_name: "GCP",
      color_code: "#eb40308a",
    },
    {
      title: "Cloud Computing Fundamentals in GCP",
      subtitle: "- GCP Training",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/345a17d9-689f-4154-a3d3-c0396c98b3bc/badges/2469792",
      alt_name: "GCP",
      color_code: "#fabd008a",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Apprenticeship",
  description:
    "I've over 5+ years of experience as a full stack developer. I've worked on building products for multiple stakeholders  as well as my personal projects.",
  header_image_path: "experience.svg",
  sections: {
    title: "Work Experience",
    experiences: [
      {
        title: "Freelancer",
        company: "Working As Freelancer",
        company_url: "https://www./",
        logo_path: "freelancer.png",
        alt_name: "freelancer-logo",
        duration: "(Feb, 2018 - Till, Date)",
        location: "Remote",
        description:
          "<li>Launched Encharta 2.0 in a team of four with add on features such as multiple content pages in a single project, audio, custom gradients, trigger and hover based animations etc. in canvas using ReactJS v18.</li><br><li>Created a PWA named Encharta for all the educators in Infosys for creating their own analogies with add on animations in their content using HTML, CSS, JavaScript and an open source library Fabric.js.</li>",
        color: "#0071C5",
      },
      {
        title: "Operations Executive",
        company: "Avestan Technologies",
        company_url: "https://www.avestantechnologies.com/index.html",
        logo_path: "at.jfif",
        alt_name: "infosys-logo",
        duration: "(Nov, 2016 - Jan, 2018)",
        location: "Nagpur",
        description:
          "<li>Implemented few UI tweaks and added new categories with add on pop-ups (video, images etc.) in an web app named “Awards For Excellence” which is used for nominating employees within Avestan for various award categories using Angular v10.</li><br><li>Implemented REST API using Node and Express to fetch the data of the classrooms, learners, educators and courses. Used socket programming for multi-user experience and other features like chat, screen, audio and video share for web based AR/VR learning platform.</li><br><li>Developed 2 new tools with an intuitive design in Lex (Learning platform for the employees of Avestan) for interactive content creation using Angular v12 which is getting used by 100+ educators for creating new interactive courses, consumed by 1000+ learners.</li>",
        color: "#0071C5",
      },
    
    ],
  },
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create MERN and MEAN Stack Projects. Below are some of my projects.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      id: "project-0",
      name: "Medicine Hub",
      url: "https://medical-hub.vercel.app/",
      repoUrl: "https://github.com/chetanchitriv/1mg-clone",
      description:
        "Medicine Hub is a full stack e-commerce web application that allows you to purchase different categories of medicine online. You can apply filters accordingly and find your favourite products in medicine hub. You can pay with debit/credit cards and in just few click your product will be delivered.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos:react",
        },
        {
          name: "Redux",
          iconifyClass: "logos:redux",
        },
        {
          name: "Express",
          iconifyClass: "simple-icons:express",
        },
       
       
        {
          name: "Stripe",
          iconifyClass: "logos:stripe",
        },
        {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
        {
          name: "Cloudinary",
          iconifyClass: "logos:cloudinary-icon",
        },
        
      ],
    },
    {
      id: "project-1",
      name: "Shoeland",
      url: "https://shoe-land-hub.vercel.app/",
      repoUrl: "https://github.com/chetanchitriv/scenic-wood-5639",
      description:
        "Famous Footwear is part of Caleres Inc. – a diverse portfolio of global footwear brands dedicated to helping every person find the perfect pair of shoes. With a legacy of over 130 years of craftsmanship and passion for fit, Caleres continues their mission of inspiring people to feel good… feet first.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos:react",
        },
        {
          name: "Redux",
          iconifyClass: "logos:redux",
        },
        {
          name: "MongoDb",
          iconifyClass: "logos:mongodb",
        },
        {
          name: "Chakra ui",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
        {
          name: "Framer Motion",
          iconifyClass: "logos:framer",
        },
      ],
    },
    {
      id: "project-2",
      name: "real-estate-management",
      url: "",
      repoUrl: "https://github.com/chetanchitriv/real-estate-management",
      description:
        "A online property management solution for real estate and physical property management. This can include residential, commercial, and land real estate. a software developed to connect property managers and potential buyers.",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos:angular-icon",
        },
        {
          name: "Express",
          iconifyClass: "simple-icons:express",
        },
        {
          name: "Nodejs",
          iconifyClass: "logos:nodejs",
        },
        {
          name: "MongoDB",
          iconifyClass: "vscode-icons:file-type-mongo",
        },
        {
          name: "Bootstrap",
          iconifyClass: "logos:bootstrap",
        },
        {
          name: "Handlebars",
          iconifyClass: "vscode-icons:file-type-handlebars",
        },
      ],
    },
    
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "portfolioProfileImage.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
